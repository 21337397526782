import { scroller } from 'react-scroll/modules';

export const customScrollTo = (id: string, offset?: number) => {
	scroller.scrollTo(id, {
		duration: 500,
		delay: 100,
		smooth: true,
		// containerId: 'ContainerElementID',
		offset: offset,
	});
};
