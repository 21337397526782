import { Backdrop, CircularProgress } from '@material-ui/core';

export default function SimpleBackdrop() {
  return (
    <div>
      <Backdrop
        // sx={{ color: '#fff', zIndex: 100 }}
        style={{ zIndex: 100 }}
        open={true}
      >
        <CircularProgress style={{ color: '#fff' }} />
      </Backdrop>
    </div>
  );
}
