export const isSpanish = navigator.language.startsWith('es-');

export const localizedStrings = {
	aboutMeTitle: isSpanish ? 'Arquitectura | Diseño | Arte 3D' : 'Architecture | Design | 3D Art',
	aboutMeBody1: isSpanish
		? 'Soy arquitecta y diseñadora especializada en modelado y renderizado 3D de espacios interiores y exteriores.'
		: 'I am an architect and designer specialized in 3D modeling and rendering of interior and exterior spaces.',
	aboutMeBody2: isSpanish
		? 'Si quieres promocionar un proyecto arquitectónico, previsualizar cómo se vería un rediseño de tu cocina, promocionar un producto o simplemente hacer realidad una idea, ¡estaré encantada de ser parte de tu proyecto!'
		: 'If you want to promote an architectural project, preview how a redesign of your kitchen would look like, promote a product, or simply make an idea a reality, I will be glad to be part of your project!',
};
