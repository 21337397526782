import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import HomePage from './Pages/HomePage/HomePage';
import reportWebVitals from './reportWebVitals';
//Router
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Material UI
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import Loading from './components/Loading';
import ProjectPage from './Pages/ProjectPage/ProjectPage';
require('typeface-montserrat');
const AppBarF = lazy(() => import('./Sections/AppBarF'));
const Footer = lazy(() => import('./Sections/Footer'));

const theme = createMuiTheme({
	typography: {
		fontFamily: 'Montserrat',
	},
	palette: {
		primary: {
			main: '#2a81c9',
		},
		secondary: {
			main: green[500],
		},
		common: {
			white: '#ffffff',
		},
	},
	spacing: 4,
});

ReactDOM.render(
	<React.StrictMode>
		<MuiThemeProvider theme={theme}>
			<Suspense fallback={<Loading />}>
				<Router>
					<AppBarF />
					<Switch>
						<Route path='/' exact component={HomePage} />
						<Route path='/project/:projectID' exact component={ProjectPage} />
						<Route>
							<h1>404</h1>
						</Route>
					</Switch>
					<Footer />
				</Router>
			</Suspense>
		</MuiThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
