import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { ProjectText } from '../../components/types';
import { isSpanish } from '../../Localization';
import { useParams } from 'react-router';
import { lazy, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const CustomGridImages = lazy(() => import('./CustomGridImages'));
const OneColumnImages = lazy(() => import('./OneColumnImages'));

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: '64px',
	},
	title: {
		textAlign: 'center',
		paddingBottom: 0,
	},
	subtitle: {
		textAlign: 'center',
		// fontWeight: '700',
		paddingBottom: '1rem',
	},
	image: {
		// display: 'block',
		width: '100%',
		// height: '90vh',
		// margin: 'auto',
	},
	companionImage: {
		// width: 'calc(100% - 2px)',
		width: '100%',
		padding: '2px',
	},
	bottomTextContainer: {
		padding: '2rem',
	},
	description: {
		textAlign: 'justify',
		paddingBottom: '1rem',
	},
}));

export default function ProjectPage() {
	const classes = useStyles();
	const { projectID } = useParams<{ projectID: string }>();
	const projectText: ProjectText = isSpanish
		? require(`../../content/${projectID}_es.json`)
		: require(`../../content/${projectID}_en.json`);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className={classes.root}>
			<Typography variant='h3' className={classes.title}>
				{projectText.titulo}
			</Typography>
			<Typography variant='h6' className={classes.subtitle}>
				{projectText.subTitulo}
			</Typography>
			{/* ***************************************************************/}
			{/************************** Main image ***************************/}
			{projectID !== 'rack' ? (
				<LazyLoadImage
					className={classes.image}
					src={`/assets/rootPage/portadas/${projectID}.png`}
					alt='cover page'
				></LazyLoadImage>
			) : (
				<div></div>
			)}
			{/* ***************************************************************/}
			{/******************* Grid of companion images ********************/}
			{projectID !== 'rack' ? (
				<CustomGridImages projectID={projectID} />
			) : (
				<OneColumnImages projectID={projectID} />
			)}
			{/* ***************************************************************/}
			{/****************** Text explaining the project ******************/}
			<div className={classes.bottomTextContainer}>
				<Typography className={classes.description}>{projectText.descriptionproyecto}</Typography>
				<Typography>{projectText.subTitulo2}</Typography>
				<ul>
					{projectText.datosrender.map((dato, index) => {
						return (
							<li key={index}>
								<Typography>{dato}</Typography>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
}
